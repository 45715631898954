import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Banner from './Components/HomeComponent/Banner';
import SaveThousands from './Components/HomeComponent/SaveThousands';
import Footer from './Components/HomeComponent/Footer';
import OurSolution from './Components/HomeComponent/OurSolution';
import HomeCTA from './Components/HomeComponent/HomeCTA';
import FirstSection from './Components/ServicesComponent/FirstSection';
import ManagementProcess from './Components/ServicesComponent/ManagementProcess';
import Services from './Components/ServicesComponent/Services';
import Success from './Components/DealComponent/Success';
import BestDeals from './Components/DealComponent/BestDeals';
import ReferralPolicy from './Components/OpportunitiesComponent/ReferralPolicy';
import Feature from './Components/OpportunitiesComponent/feature';
import ExclusiveOpportunities from './Components/OpportunitiesComponent/ExclusiveOpportunities';
import MembershipTiers from './Components/MembershipComponent/MembershipTiers';
import MakesUs from './Components/MembershipComponent/MakesUs';
import OurPurpose from './Components/AboutComponent/OurPurpose';
import OurStory from './Components/AboutComponent/OurStory';
import MakingDifference from './Components/AboutComponent/MakingDifference';
import WhyChooseUs from './Components/AboutComponent/WhyChooseUs';
import TermsConditions from './Components/FooterComponent/TermsConditions';
function HomePage() {
  return (
    <>
      <Banner
      videoSrc="/HomePage/Home_Banner.mp4" 
      title="Deals & Vendor Management"
      subtitle="We Discover & Build Deals to Save you Thousands"
       />
       <SaveThousands/>
       <OurSolution/>
       <HomeCTA
       header = "We don’t just provide solutions—We’re here to support your success."
      btntext= "Learn More About Us"
      />
       <Footer/>
    </>
  );
}
function ServicesPage() {
  return (
    <>
      <Banner
      videoSrc="/ServicesPage/Services_Banner.mp4" 
        title="Deals & Procurement Management"
      subtitle="Lead the Vendors from Discovery to Delivery"
       />
      <FirstSection/>
      <ManagementProcess/>
      <Services/>
      <HomeCTA
       header = "Schedule a meeting."
       text = "Find the right solution today."
      btntext= "Message Us"
      />
       <Footer/>
    </>
  );
}
function DealPage() {
  return (
    <>
      <Banner
      videoSrc="/DealsPage/Deals_Banner.mp4" 
        title="Deals Management"
      subtitle="Save on Every Deal for Higher ROI"
       />
      <Success/>
      <BestDeals/>
      <HomeCTA
       header = "Earn and Grow"
       text = "Help clients and partners succeed and earn rewards in return."
      btntext= "Learn How to Make $"
      />
       <Footer/>
    </>
  );
}

function OpportunitiesPage() {
  return (
    <>
      <Banner
      videoSrc="/OpportunitiesPage/Opportunities_Banner.mp4" 
        title="Opportunities that Drive Results"
      subtitle="Connecting Vendors to Their Next Big Win"
       />
       <ReferralPolicy/>
       <ExclusiveOpportunities/>
       <Feature/>
        <HomeCTA
       header = "Ready to Explore What’s Possible?"
       text="Your next opportunity could be just a step away. 
Join us at Nazeq today—your future partners are waiting"
      btntext= "Lets Get Started"
      />
       <Footer/>
    </>
  );
}
function MembershipPage() {
  return (
    <>
      <Banner
      videoSrc="/HomePage/Membership_Banner.mp4" 
        title="Your Business Deserves More"
      subtitle="Membership That Helps You Save Big Without Breaking the Bank"
       />
       <MembershipTiers/>
       <MakesUs/>
       <HomeCTA
          header = "Ready to Explore What’s Possible?"
          text="Not sure which membership is best for your business?
Let’s talk. Schedule a free consultation with one of our experts."
         btntext= "Message Me"/>
       <Footer/>
    </>
  );
}
function AboutPage() {
  return (
    <>
      <Banner
      videoSrc="/HomePage/About_Banner.mp4" 
      title="Our Journey, Values & Vision"
      subtitle="Trusted Partner for Your Business"
       />
      <OurPurpose/>
      <OurStory/>
      <MakingDifference/>
      <WhyChooseUs/>
      <HomeCTA
          header = "Have Questions? "
          text="Connect with Us!"
         btntext= "Message Me"/>
       
       <Footer/>
    </>
  );
}
function ContactUsPage() {
  return (
    <>
      <Banner
      videoSrc="/HomePage/Home_Banner.mp4" 
       />

       <Footer/>
    </>
  );
}
function TOCPage() {
  return (
    <>
      <Banner
      videoSrc="/HomePage/Home_Banner.mp4" 
       />
       <TermsConditions/>
       <Footer/>
    </>
  );
}
function LegalNoticePage() {
  return (
    <>
      <Banner
      videoSrc="/HomePage/Home_Banner.mp4" 
       />
       <TermsConditions/>
       <Footer/>
    </>
  );
}
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/deals" element={<DealPage />} />
        <Route path="/opportunities" element={<OpportunitiesPage />} />
        <Route path="/membership" element={<MembershipPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/terms-conditions" element={<TOCPage />} />
        <Route path="/legal-notice" element={<LegalNoticePage />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
