import React, { useEffect, useRef } from "react";
import "./Banner.css";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Banner = ({ videoSrc, title, subtitle }) => {
  // Media Queries
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Ref to access video element
  const videoRef = useRef(null);

  // Set playback rate when the component mounts
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2.0; // Adjust playback speed if needed
    }
  }, []);

  return (
    <div className="banner">
      {/* Navigation Menu */}
      <nav className={`navbar ${isMobile ? "navbar-mobile" : ""}`}>
        <ul
          className={`menu ${
            isMobile ? "menu-mobile" : isTablet ? "menu-tablet" : "menu-desktop"
          }`}
        >
          <div className="logo">
            <a href="/" className="home-button">
              <img
                src="/HomePage/nazeqlogo.png"
                alt="Logo"
                className="logo-img"
              />
            </a>
          </div>
          <li>
            <NavLink to="/services" activeClassName="active-link">
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/deals" activeClassName="active-link">
              Deals
            </NavLink>
          </li>
          <li>
            <NavLink to="/opportunities" activeClassName="active-link">
              Opportunities
            </NavLink>
          </li>
          <li>
            <NavLink to="/membership" activeClassName="active-link">
              Membership
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active-link">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact-us" activeClassName="active-link">
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* Banner Main Section */}
      <div className="banner-container">
        {/* Left Side Content */}
        <div className="banner-content">
          <h1 className="banner-title">{title}</h1>
          <p className="banner-subtitle">
            {subtitle}
          </p>
        </div>

        {/* Right Side Video */}
        <div className="banner-video">
          <video ref={videoRef} src={videoSrc} autoPlay loop muted></video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
