import React from "react";
import "./WhyChooseUs.css";

const cardData = [
  {
    image: "/AboutPage/exclusive-savings.png",
    title: "Exclusive Savings",
    description:
      "Access the best deals that help you cut costs and boost your ROI.",
  },
  {
    image: "/AboutPage/find-deals.png",
    title: "Find Deals at Lower Cost",
    description:
      "We discover deals, lower prices, and manage the process from onboarding to payment.",
  },
  {
    image: "/AboutPage/supportive-community.png",
    title: "Supportive Community",
    description:
      "Connect with a network of businesses and individuals who are focused on growth and collaboration.",
  },
];

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <h1 className="why-header">Why Choose Us</h1>
      <div className="card-container">
        {cardData.map((card, index) => (
          <div className="why-card" key={index}>
            <img src={card.image} alt={card.title} className="card-image" />
            <h2 className="card-subheader">{card.title}</h2>
            <p className="card-description">{card.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
