import React from "react";
import "./BestDeals.css";


const services = [
  {
    img:"/DealsPage/elite-clubs.png",
    title: "Elite Clubs",
    description: "Find the best exclusive social sports club membership deals.",
    icon: "🏌️",
  },
  {
    img: "./DealsPage/marketing-sales.png",
    title: "Marketing & Sales",
    description: "Find top-tier marketing and sales solutions to expand your business.",
    icon: "🛍️",
  },
  {
    img: "./DealsPage/talent-acquisition.png",
    title: "Talent Acquisition",
    description: "Build the best team at a lower cost.",
    icon: "📊",
  },
  {
    img: "./DealsPage/project-management.png",
    title: "Project Management",
    description: "Turn your goals into realities with the best management & analytics services.",
    icon: "📋",
  },
  {
    img: "./DealsPage/finance-accounting.png",
    title: "Finance & Accounting",
    description: "Get financial and tax-related success with strategic investment, taxation, and wealth management.",
    icon: "💰",
  },
  {
    img: "./DealsPage/biz-estate.png",
    title: "Biz & Estate Acquisition",
    description: "Discover the best deals for buying or selling a business, including investment opportunities.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/AI-Robot-Assistant.png",
    title: "AI Robot Assistant",
    description: "Enhance your operations with intelligent, AI-powered assistants.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/Funding-Services.png",
    title: "Funding Services",
    description: "Find and negotiate the best funding and financing solutions for your business.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/Analytics.png",
    title: "Analytics",
    description: "Turn data into decisions with advanced analytics.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/Analytics.png",
    title: "CSR (Corporate Social Responsibility)",
    description: "Turn data into decisions with advanced analytics.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/Call_Center.png",
    title: "Call Center",
    description: "Optimize customer support with top-tier call center solutions.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/Call_Center.png",
    title: "Gifts (Coming Soon)",
    description: "Reward your customers without impacting your profits.",
    icon: "🏢",
  },
  {
    img: "./DealsPage/technology.png",
    title: "Technology",
    description: "Ensure top performance with rigorous QA testing.",
    icon: "🏢",
  },
];

const BestDeals = () => {
  return (
    <section className="best-deals-section">
      <h2 className="best-deals-header">Best Deals of the Season, Designed for Your Business</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="deals-card">
            <div className="service-img">
              <img src={service.img} alt={service.title} />
            </div>
            <h3 className="service-title">
              {service.icon} <span>{service.title}</span>
            </h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BestDeals;
