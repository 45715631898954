import React from 'react';
import './Success.css';

const Success = () => {
  return (
    <>
    <div className="growth-phase-section">
          <div className="growth-content">
              <img src="/DealsPage/firstsection.png" alt="Excited Man" className="growth-image" />
              <div className="growth-text-content">
                  <h2 className="growth-heading">"Success begins with the deals you purchase, not just the
                  ones you sell."</h2>
                  <p className="growth-paragraph">
                  At Nazeq, we secure the best deals that cut costs, helping you operate more
                  effectively and achieve higher financial results.
                  </p>
              </div>
          </div>
      </div>
    </>
  );
};

export default Success;
