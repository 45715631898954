import React from "react";
import "./MembershipTiers.css";


const MembershipTiers = () => {
  return (
    <section className="membership-container">
      <h2 className="membership-header">MEMBERSHIP TIERS BUILT TO SAVE YOU MORE</h2>
      <p className="membership-subheader">
        <strong>Every business is unique</strong>, and so are our <strong>memberships</strong>—tailored just for you.
        With us, you're not just <strong>saving money</strong>—you're investing in your <strong>business's growth</strong>.
      </p>
      <div className="membership-tiers">
        <img src="/MembershipPage/GoldTier.png" alt="Gold Tier" className="gold-tier-image" />
        <img src="/MembershipPage/PlatinumTier.png" alt="Platinum Tier" className="platinum-tier-image" />
        <img src="/MembershipPage/EnterpriseTier.png" alt="Enterprise Tier" className="enterprise-tier-image" />
        <img src="/MembershipPage/CustomizeDeals.png" alt="Customize Deals" className="customize-tier-image" />
      </div>
    </section>
  );
};

export default MembershipTiers;
