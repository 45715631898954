import React from "react";
import "./ReferralPolicy.css";

const ReferralPolicy = ({header, text, btntext}) => {
  return (
    <div className="first-section">
      <div className="first-content">
        <h2><strong>Modifications to Our Referral Policy</strong></h2>
        <p className="first-statement">
        As of January 1, 2025, Nazeq has shifted its focus away from monetized referral opportunities. While we no longer offer direct financial
rewards for referrals, we remain committed to helping you build meaningful connections and explore new opportunities through our
platform.
        </p>
        <p className="first-statement">
        or those seeking monetized referral programs, we highly recommend visiting Treasure Hint. Their program offers a variety of financial
incentives tailored to recognize and value your network's contributions. Treasure Hint offers a wide range of options, allowing you to
earn rewards, all tailored to recognize and value your network's contributions.

To learn more and get started with monetized referrals, visit TreasureHint.com.
        </p>
      </div>
    </div>
  );
};

export default ReferralPolicy;