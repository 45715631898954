import React from "react";
import "./MakingDifference.css";

const MakingDifference = () => {
  return (
    <section className="making-difference-section">
      <h1 className="making-difference-header">We Value Making a Difference</h1>
      <p className="making-difference-description">
        Our success is built on a foundation of strong principles that guide every decision we make.
        These values define how we approach our business, our partners, and the individuals we empower.
      </p>

      <div className="value-box">
        <img src="/AboutPage/checkmark.png" alt="Tick" className="tick-icon" />
        <div className="value-text">
          <h2 className="value-subheader">Opportunity for Everyone</h2>
          <p className="value-description">
            What we do is make a commitment to open up earnings opportunities for individuals.
          </p>
        </div>
      </div>

      <div className="value-box">
        <img src="/AboutPage/checkmark.png" alt="Tick" className="tick-icon" />
        <div className="value-text">
          <h2 className="value-subheader">Results That Matter</h2>
          <p className="value-description">
            Our focus is always on driving real, measurable results—whether it’s securing top deals
            for businesses or helping individuals earn more money.
          </p>
        </div>
      </div>

      <div className="value-box">
        <img src="/AboutPage/checkmark.png" alt="Tick" className="tick-icon" />
        <div className="value-text">
          <h2 className="value-subheader">Growth Through Collaboration</h2>
          <p className="value-description">
            We understand the value of collaboration—working together to achieve shared goals.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MakingDifference;
