import React, { useState, useEffect } from "react";
import "./OurSolution.css";


const OurSolution = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <section className="our-solution">
      {/* Header */}
      <h1 className="solution-header">Our Solution for Maximizing ROI</h1>

      {/* Discover Section */}
      <div className="solution-section">
      <div className="solution-image-container">
          <div className="image-corner"></div>
          <img src="/HomePage/discover.png" alt="Discover" className="discover-image" />
        </div>
        <div className="discover-content">
          <div className="solution-subheader"> <span
              style={{
                backgroundColor: "#312e2d",
                color: "#f8cf40",
                padding: isMobile ? "0.3vw 1vw" : "1px 2px",
                borderRadius: isMobile ? "2vw" : "10px",
                fontSize: isMobile ? "2vw" : "27px",
              }}
            >
              Discover 
            </span> Deals</div>
          <p className="solution-description">
          To find great deals turning each purchase 
into real savings and maximizing your ROI.
          </p>
        </div>
      </div>

      {/* Manage Section */}
      <div className="solution-section manage">
      
        <div className="solution-content manage-content">
        <div className="solution-subheader"> <span
              style={{
                backgroundColor: "#312e2d",
                color: "#f8cf40",
                padding: isMobile ? "0.3vw 1vw" : "1px 2px",
                borderRadius: isMobile ? "2vw" : "10px",
                fontSize: isMobile ? "2vw" : "27px",
               
              }}
            >
              Manage
            </span> Vendor Connection</div>
          <p className="solution-description">
          To streamline vendor management from discovery to invoicing.
          </p>
        </div>
        <div className="solution-image-container">
          <div className="image-corner"></div>
          <img src="/HomePage/manage.png" alt="Manage" className="manage-image" />
        </div>
      </div>

      {/* Opportunities Section */}
      <div className="solution-section">
      <div className="solution-image-container">
          <div className="image-corner"></div>
          <img
            src="/HomePage/opportunities.png"
            alt="Opportunities"
            className="discover-image"
          />
        </div>
        <div className="solution-content opportunities-content">
        <div className="solution-subheader"> <span
              style={{
                backgroundColor: "#312e2d",
                color: "#f8cf40",
                padding: isMobile ? "0.3vw 1vw" : "1px 2px",
                borderRadius: isMobile ? "2vw" : "10px",
                fontSize: isMobile ? "2vw" : "27px",
               
              }}
            >
              Opportunities 
            </span> for Growth</div>
          <p className="solution-description">
          To discover new ways to enhance your business potential.
          </p>
        </div>
     
      </div>
    </section>
  );
};

export default OurSolution;
