import React from "react";
import "./ExclusiveOpportunities.css";

const opportunities = [
  {
    title: "Your Business in the Spotlight",
    description: "Showcase your products and services to a network that values what you bring to the table.",
  },
  {
    title: "Trust That Builds Bridges",
    description: "We go beyond transactions, fostering meaningful connections that deliver long-term value for your business.",
  },
  {
    title: "Exclusive Opportunities to Great Deals",
    description: "Our curated opportunities are designed to make a measurable impact on your bottom line.",
  },
];

const ExclusiveOpportunities = () => {
  return (
    <section className="exclusive-container">
        <div className="border-area">
        <h2 className="exclusive-header">Exclusive Opportunities That Move Your Business Forward</h2>
      <p className="exclusive-subheader">
        Every step with Nazeq is an investment in your future, helping your business grow and build a lasting reputation.
      </p>
      <div className="exclusive-list">
        {opportunities.map((item, index) => (
          <div key={index} className="exclusive-item">
            <img src="/OpportunitiesPage/Star-bullets.png" alt="Star Icon" className="star-icon" />
            <div className="exclusive-text">
              <h3 className="exclusive-title">{item.title}</h3>
              <p className="exclusive-description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
        </div>
     
    </section>
  );
};

export default ExclusiveOpportunities;
