import React from "react";
import "./OurStory.css";

const OurStory = () => {
  return (
    <div className="team-background-section">
      <div className="content-team">
        <h1>Our Story:<br/>Where It All Began</h1>
        <p>
        In 2013, we saw a common struggle: businesses spending too much
time and effort on deals and procurement management. We knew
there had to be a better way, and that's when Nazeq was born. Our
mission was clear—create simple solutions that would make the
management process less of a hassle and more of an advantage.<br/><br/>

Over the years, we've grown alongside our clients, helping them
simplify their operations, save money, and discover new ways to
thrive. We're proud of where we started but even prouder of where
we're going—together with you.
        </p>
      </div>
    </div>
  );
};

export default OurStory;