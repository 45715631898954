import React from 'react';
import './TermsConditions.css';

const TermsConditions = () => {
  return (
   
    <div className="Bg-control-privacy">
     <div> <h1 className="privacy-banner">Terms and condition</h1></div>
    <div className="privacy-policy-container">
    <h2 className="privacy-subheader">Effective Date: January 2025</h2>
    <p className="privacy-paragraph">Welcome to American Team</p>
    <h2 className="privacy-header">Acceptance of Terms</h2>
    <p className="privacy-paragraph">Thank you for choosing American Team LLC. We are committed to offering you a variety of business software solutions
    designed to boost your operational efficiency and propel your company to unprecedented levels of success.</p>
    <p className="privacy-paragraph">These Terms of Service ("Terms") govern your access to and use of all American Team software, websites, SMS
communications, and services (collectively referred to as "Services"). By engaging with our Services, you acknowledge and
consent to be bound by these Terms. If you find any aspect of our Terms unacceptable, please refrain from using our
Services.</p>
   
    <h2 className="privacy-subheader"> 1. Service Provision</h2>
    <p className="privacy-paragraph"> American Team LLC provides a range of software solutions tailored to enhance business efficiency, including but not
limited to Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), SMS notifications, and more.

Some of our services may include sending SMS updates, notifications, or promotional content to users who have explicitly
opted in.</p>
    <h2 className="privacy-subheader"> 2. User Responsibilities</h2>
    <p className="privacy-paragraph"> 
    <ul>
        <li>You are responsible for all activities that occur under your account.</li>
        <li>You must comply with all applicable laws when using the Services and may not use them for unlawful or fraudulent
        purposes.</li>
        <li>You may not misuse our Services by interfering with their normal operation or attempting to access them using a
        method other than through the interfaces and instructions provided by us.</li>
   </ul>
    </p>
    <h2 className="privacy-subheader">3. SMS Messaging Terms </h2>
    <h2 className="privacy-subheader">3.1 SMS Consent & Opt-In </h2>
    <p className="privacy-paragraph">By providing your phone number to American Team LLC, you consent to receive SMS messages regarding:
    <ul>
        <li>Service updates</li>
        <li>Transactional alerts</li>
        <li>Promotional offers</li>
   </ul>
   Message frequency varies. Standard message and data rates may apply.
    </p>

    <h2 className="privacy-subheader">3.2 Opt-Out Instructions</h2>
    <p className="privacy-paragraph">You can opt out of receiving SMS messages at any time by replying "STOP" to any message from us. Once you opt out, you
    will no longer receive SMS notifications unless you opt in again.</p>
    
    <h2 className="privacy-subheader">3.3 Assistance & Support</h2>
    <p className="privacy-paragraph">For assistance regarding SMS messages, reply "TEAM" or contact us at NY_CS@AmericanTeam.Space.</p>
   
    <h2 className="privacy-subheader">3.4 Privacy & Data Usage</h2>
    <p className="privacy-paragraph">We do not share or sell SMS opt-in data to third parties for marketing purposes. For more details on how we collect and
    use personal information, refer to our   <strong><a href="/privacy-policy" style={{ color: '#000000' }}>Privacy Policy.</a></strong></p>
   
    <h2 className="privacy-subheader">4. Fees and Payments</h2>
    <p className="privacy-paragraph">
American Team reserves the right to require payment of fees for certain services or for all services offered. You are
obligated to pay all applicable fees as specified on the website in relation to the services you have selected.</p>
   
    <h2 className="privacy-subheader">5. Intellectual Property Rights</h2>

<p className="privacy-paragraph">
American Team reserves the right to require payment of fees for certain services or for all services offered. You are
obligated to pay all applicable fees as specified on the website in relation to the services you have selected.</p>
   

    <h2 className="privacy-subheader">6. Privacy and Data Protection</h2>
      <p className="privacy-paragraph">American Team is committed to protecting your privacy and data. Our Privacy Policy explains how we treat your personal
data and protect your privacy when you use our Services.

</p>
     
      <h2 className="privacy-subheader">7. Termination and Account Cancellation</h2>
      <p className="privacy-paragraph">
You may discontinue use of our Services at any time. American Team reserves the right to terminate your access to any or
all parts of the Services at any time, for any reason or no reason, without prior notice.
      </p>

      <h2 className="privacy-subheader">8. Disclaimer of Warranties</h2>
      <p className="privacy-paragraph">American Team provides the services on an "as is" and "as available" basis. While we strive to provide reliable services, we
      do not guarantee that these services will always be error-free or uninterrupted.</p>
      <h2 className="privacy-subheader">9. Limitation of Liability</h2>
      <p className="privacy-paragraph">In no case will American Team, its suppliers, or licensors be responsible for any special, incidental, or consequential
      damages, including:</p>
    
    <p className="privacy-paragraph">
    <ul>
        <li>The cost of obtaining replacement goods or services
        </li>
        <li>Loss of use, data corruption, or interruption</li>
        <li>Liability due to contract, negligence, or other legal theory</li>
   </ul>
    </p>

    <h2 className="privacy-subheader">10. General Terms</h2>
    <p className="privacy-paragraph">These Terms constitute the entire agreement between you and American Team LLC regarding the use of the Services,
    superseding any prior agreements.</p>
    
      <h2 className="privacy-subheader">11. Changes to These Terms</h2>
      <p className="privacy-paragraph">
American Team reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is
material, we will provide at least 30 days' notice before the new terms take effect.</p>
      
      <h2 className="privacy-subheader">12. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the state/country where American Team
      LLC is headquartered, without regard to its conflict of law provisions.  </p>
      <h2 className="privacy-subheader">13. Contact Information</h2>
      <p>For questions about these Terms, please contact us:  </p>
      <ul>
        <li>Email: <a href="mailto:NY_CS@AmericanTeam.Space" style={{ color: '#000000' }}>NY_CS@AmericanTeam.Space</a></li>
        <li>Phone Number: +1 (888) 832-6032</li>
   </ul>

    </div>
    </div>
  );
};

export default TermsConditions;
