import React from "react";
import "./FirstSection.css";

const FirstSection = ({header, text, btntext}) => {
  return (
    <div className="first-section">
      <div className="first-content">
        <p className="first-statement">
        We simplify the complexity of processes for busy teams, allowing them to focus on achieving exceptional results with ease.
        </p>
      </div>
    </div>
  );
};

export default FirstSection;
