import React from "react";
import "./Services.css";

const servicesData = [
  {
    title: "Complete Visibility",
    description: "Gain real-time insights for informed decision-making.",
    icon: "/ServicesPage/visibility.png",
  },
  {
    title: "Smarter Decisions",
    description: "Real-time data for strategic choices.",
    icon: "/ServicesPage/decisions.png",
  },
  {
    title: "Bigger Savings",
    description: "Cut costs effortlessly.",
    icon: "/ServicesPage/savings.png",
  },
  {
    title: "Stronger Partnerships",
    description: "Build long-term relationships with effective collaboration.",
    icon: "/ServicesPage/partnerships.png",
  },
  {
    title: "Zero Risk",
    description: "Stay protected with robust risk management.",
    icon: "/ServicesPage/risk.png",
  },
  {
    title: "Full Compliance",
    description: "Automated compliance tools to keep you safe.",
    icon: "/ServicesPage/compliance.png",
  },
  {
    title: "Eliminate the Hustle",
    description: "Automated workflows streamline your tasks.",
    icon: "/ServicesPage/hustle.png",
  },
];

const Services = () => {
  return (
    <section className="services-section">
      <h2 className="services-header">Why Services Make a Difference</h2>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.icon} alt={service.title} className="service-icon" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
