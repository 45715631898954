import React from 'react';
import './feature.css';

const feature = () => {
  return (
    <div className="zero-cost-container">
      <div className="image-section">
        <img src="/OpportunitiesPage/feature.png" alt="Every Feature " />
      </div>
      <div className="text-section">
        <p>Every feature we’ve built, every connection we foster, and every deal
we present has one purpose: to help you succeed. Together, we’re
building a stronger, more connected marketplace that delivers results.</p>
      </div>
    </div>
  );
};

export default feature;
