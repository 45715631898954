import React from "react";
import "./ManagementProcess.css";

const ManagementProcess = () => {
  const processSteps = [
    { title: "Assessment", description: "Evaluate vendors, products, and services while ensuring compliance and mitigating risks.", image: "/ServicesPage/assessment.png" },
    { title: "Negotiation", description: "Secure the best deals through effective discussions.", image: "/ServicesPage/negotiation.png" },
    { title: "Discovery", description: "Identify vendors and deals that align with your business needs and deliver high ROI.", image: "/ServicesPage/discovery.png" },
    { title: "Onboarding", description: "Seamlessly integrate vendors into workflows while providing clear operational guidelines.", image: "/ServicesPage/onboarding.png" },
    { title: "Procurement", description: "Efficiently acquire products or services that meet established business requirements.", image: "/ServicesPage/procurement.png" },
    { title: "Invoice", description: "Ensure accuracy and streamline transactions for seamless financial operations.", image: "/ServicesPage/invoice.png" },
    { title: "Monitor", description: "Continuously track performance to ensure compliance and maximize vendor value.", image: "/ServicesPage/monitor.png" },
  ];

  return (
    <div className="management-process">
      <h2 className="process-header">Our Management Process Works</h2>
      <p className="process-statement">Simple, Effective, Efficient</p>

      <div className="process-grid">
        {processSteps.map((step, index) => (
          <div key={index} className="process-card">
            <div className="image-container">
              <img src={step.image} alt={step.title} className="process-image" />
            </div>
            <div className="process-content">
              <span className="process-subheader">{step.title}</span>
              <p className="process-description">{step.description}</p>
            </div>
          </div>
        ))}
      </div>

      <p className="process-summary">
        At the end of the day, our management processes don’t need to be complicated.  
        <br />
        Our solution simplifies the process, reduces headaches, and makes your teams more effective.
      </p>
    </div>
  );
};

export default ManagementProcess;
