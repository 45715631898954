import React from "react";
import "./SaveThousands.css";

const SaveThousands = () => {
  return (
    <section className="milestones-section">
      <h2 className="milestones-header">Save thousands on essential purchases that increase your ROI.</h2>
      <div className="milestones-container">
        <div className="milestone">
          <h3 className="milestone-number">40%</h3>
          <h4 className="milestone-subheader">Achieve up to a 40% reduction in
          product and project costs. </h4>
        </div>
        <div className="divider"></div>
        <div className="milestone">
          <h3 className="milestone-number">200%</h3>
          <h4 className="milestone-subheader">Achieve up to a 200% increase in ROI.</h4>
        </div>
        <div className="divider"></div>
        <div className="milestone">
          <h3 className="milestone-number">500</h3>
          <h4 className="milestone-subheader">Get up to 500 Expert to Find the Best
          Deals for Your Business.</h4>
        </div>
      </div>
    </section>
  );
};

export default SaveThousands;