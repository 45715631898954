import React from "react";
import "./HomeCTA.css";

const HomeCTA = ({header, text, btntext}) => {
  return (
    <div className="cta-section">
      <div className="cta-content">
        <h1 className="cta-header">{header}</h1>
        <p className="cta-statement">
         {text}
        </p>
      </div>
      <div className="cta-button-container">
        <a href="/contact-us" className="cta-button">
           {btntext}
        </a>
      </div>
    </div>
  );
};

export default HomeCTA;
