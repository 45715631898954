import React, { useState, useEffect } from "react";
import "./MakeUs.css";

const MakesUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="our-solution-make">
      <h1 className="solution-header-make">What makes us the BEST?</h1>

      {/* Save Money */}
      <div className="solution-section-make">
        <div className="solution-image-container-make">
          <div className="image-corner-make"></div>
          <img src="/MembershipPage/SaveMoney.png" alt="Save Money" className="discover-image-make" />
        </div>
        <div className="solution-content-make">
          <div className="solution-subheader-make">
            <span
              className="highlight-make"
              style={{
                fontSize: isMobile ? "2vw" : "27px",
              }}
            >
              Save
            </span>{" "}
            Money
          </div>
          <p className="solution-description-make">
            Our membership tiers offer significant savings on your business expenses.
          </p>
        </div>
      </div>

      {/* Expert Support */}
      <div className="solution-section-make manage-make">
        <div className="solution-content-make">
          <div className="solution-subheader-make">
            <span
              className="highlight-make"
              style={{
                fontSize: isMobile ? "2vw" : "27px",
              }}
            >
              Expert
            </span>{" "}
            Support
          </div>
          <p className="solution-description-make">
            Our team of experts is here to help you grow your business
          </p>
        </div>
        <div className="solution-image-container-make">
          <div className="image-corner-make"></div>
          <img src="/MembershipPage/ExpertSupport.png" alt="Expert Support" className="manage-image-make" />
        </div>
      </div>

      {/* Exclusive Benefits */}
      <div className="solution-section-make">
        <div className="solution-image-container-make">
          <div className="image-corner-make"></div>
          <img src="/MembershipPage/ExclusiveBenefits.png" alt="Exclusive Benefits" className="discover-image-make" />
        </div>
        <div className="solution-content-make">
          <div className="solution-subheader-make">
            <span
              className="highlight-make"
              style={{
                fontSize: isMobile ? "2vw" : "27px",
              }}
            >
              Exclusive
            </span>{" "}
            Benefits
          </div>
          <p className="solution-description-make">
            Enjoy access to exclusive discounts, tools, and connection opportunities.
          </p>
        </div>
      </div>

      {/* Flexible Options */}
      <div className="solution-section-make manage-make">
        <div className="solution-content-make">
          <div className="solution-subheader-make">
            <span
              className="highlight-make"
              style={{
                fontSize: isMobile ? "2vw" : "27px",
              }}
            >
              Flexible
            </span>{" "}
            Options
          </div>
          <p className="solution-description-make">
            Select from a range of membership tiers that cater to different business sizes and needs.
          </p>
        </div>
        <div className="solution-image-container-make">
          <div className="image-corner-make"></div>
          <img src="/MembershipPage/FlexibleOptions.png" alt="Flexible Options" className="manage-image-make" />
        </div>
      </div>
    </section>
  );
};

export default MakesUs;
