import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo-section">
          <a href="/">
          <img src="/HomePage/nazeqlogo.png" alt="More club Logo" className="footer-logo" />
          </a>
        </div>
        <div className="make-row">
        <div className="footer-menu">
          <div className="footer-column"><h4> <a href="/services">Services</a></h4><p>Simplifying Vendor
          Managemen</p></div>
          <div className="footer-column"><h4><a href="/deals">Deals</a></h4><p>Save $ Thousands
          on Deals</p></div>
          <div className="footer-column"><h4><a href="/opportunities">Opportunities</a></h4><p>Create Value Through
          Connections</p></div>
          <div className="footer-column"><h4><a href="/membership">MEMBERSHIP</a></h4><p>Business Tiers Build to
          Save</p></div>
          <div className="footer-column"><h4><a href="/about">ABOUT US</a></h4><p>Who We Are</p></div>
        </div>
        <div className="footer-bottom">
        <div className="footer-bottom-column">  <p><a href="/terms-conditions">Terms and Conditions</a></p></div>
        <div className="footer-bottom-column">  <p><a href="/legal-notice">Legal Notice</a></p></div>
        <div className="footer-bottom-column">  <p>Copyright 2025 Nazeq</p> </div>       
        </div> 
        </div>
        <div className="vertical-divider"></div>
        <div className="footer-contact">
          <h4>Connect with Us</h4>
          <p><i className="fas fa-map-marker-alt"></i>&emsp; New York | New Jersey</p>
          <p><i className="fas fa-clock"></i>&emsp;Monday - Friday<br />&emsp;&emsp;7:00 a.m. - 7:00 p.m. EST</p>
          <div className="social-links2">
           <a href="https://www.facebook.com/people/Treasure-Hint/61562184812103/" target="_blank" rel="noopener noreferrer">
           <i className="fab fa-facebook"></i>
           </a>
           <a href="https://www.instagram.com/treasurehintcom/" target="_blank" rel="noopener noreferrer">
           <i className="fab fa-instagram"></i>
           </a>
           <a href="https://x.com/TreasureHintcom" target="_blank" rel="noopener noreferrer">
           <i className="fab fa-x-twitter"></i>
           </a>
           <a href="https://www.linkedin.com/company/treasure-hint/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
           <i className="fab fa-linkedin"></i>
           </a>
           </div>
        </div>
        </div>
    </footer>
  );
};

export default Footer;

