import React from "react";
import "./OurPurpose.css";

const OurPurpose = () => {
    return (
        <div className="first-section">
          <div className="first-content">
            <h2 className="purpose">Our Purpose</h2>
            <p className="first-statement">
            We simplify the complexity of processes for busy teams, allowing them to focus on achieving exceptional results with ease.
            </p>
            <p className="second-statement">
           <strong> It’s about more than just solutions—it’s about making things easier.</strong>
            </p>
          </div>
        </div>
      );
};

export default OurPurpose;